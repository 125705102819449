<template>
<!-- 体育协会 列会列表数据展示 -->
	<div>
		<div class="container darkBack">
			<!-- 标题与搜索框 -->
			<div class="title">
				<div class="whiteFont font20">协会列表</div>
				<input type="text" v-model="value" placeholder="请输入" @keyup.enter="search">
				<div class="search" @click="search">搜索</div>
			</div>
			<!-- 选择器 -->
			<!-- <div class="select">
				<div class="font17 blueFont">越城区</div>
				<div class="triangle"></div>
			</div> -->
			<span style="color:#7EA7E2;">{{pitchon}}</span>
			<img src="../../../assets/images/pull-down.png" style="cursor: pointer;" @click="countiesopen" v-if="iconShow">
			<ul class="counties" v-show="counties">
                <li class="countiesli" v-for="(item,index) in select" :key="index" @click="countiesclick(item)">{{item.title}}</li>
            </ul>
			<!-- 表格 -->
			<table id="thead">
				<thead style="text-align:center;">
					<th style="width:10%;"></th>
					<th style="width:30%;">协会名称</th>
					<th style="width:20%;">协会联系人</th>
					<th style="width:30%;">联系人电话</th>
					<th style="width:10%;">总运动</th>
				</thead>
			</table>
			<!-- <vueSeamlessScroll :data="membersInfo" class="seamlessWarp" :class-option="classOption"> -->
			<vueSeamlessScroll :data="member.membersInfo" class="seamlessWarp" :class-option="classOption">
				<table id="table">
					<tr v-for="(item,index) in member.membersInfo" :key="index" @click="asspopoutshow(item.id)">
						<td style="cursor:pointer;width:10%;" v-if="index == 0">
							<div class="first">1</div>
						</td>
						<td style="cursor:pointer;width:10%;" v-else-if="index == 1">
							<div class="second">2</div>
						</td>
						<td style="cursor:pointer;width:10%;" v-else-if="index == 2">
							<div class="third">3</div>
						</td>
						<td style="cursor:pointer;width:10%;" v-else>{{index+1}}</td>
						<td style="cursor:pointer;width:30%;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;">{{item.association}}</td>
						<td style="width:20%;">{{item.name}}</td>
						<td style="width:30%;">{{item.phone}}</td>
						<td style="width:10%;">{{item.sports_heats}}</td>
					</tr>
				</table>
			</vueSeamlessScroll>
		</div>
	</div>
</template>

<script>
	import vueSeamlessScroll from 'vue-seamless-scroll'
    import {getAjax, timestamp} from "../../../assets/js/websocket";
	export default {
		components:{
			vueSeamlessScroll,
		},
		data() {
			return{
				iconShow:true,
				counties:false, //下拉框是否显示
				pitchon:"绍兴市",
				select:[{
						title:'绍兴市',
						id:330600
					},{
						title:'越城区',
						id:330602
					},{
						title:'新昌县',
						id:330624
					},{
						title:'诸暨市',
						id:330681
					},{
						title:'柯桥区',
						id:330621
					},{
						title:'上虞区',
						id:330682
					},{
						title:'嵊州市',
						id:330683
					},
				],
				member:{
					membersInfo:[]
				},
				value:"",
			}
		},
        created() {
            var that = this
        },
        methods:{
			// 下拉箭头选择显示隐藏
			countiesopen(){
				if(this.counties === false){
					this.counties = true;
				}else{
					this.counties = false;  
				}
			},
			countiesclick(id){
				let _this = this;
				// console.log(id)
				var stree = event.target.innerText;
				this.pitchon = stree;
				this.counties = false;
				// console.log(stree)
				if(stree == "绍兴市") this.getassociation("市",stree);
				else this.getassociation("区",stree);
				
				
			},
			asspopoutshow(id){
				// console.log(id)
				window.associationId = id;
				this.$store.state.asspopout = true
			},
			search(){
				let that = this;
				// console.log(that.value)
				var data = {
					token: window.getToken(),
					app_id: window.appId,
					time: timestamp()
				}
				getAjax({
					url: "/map/screen/getSportsAssociationAll?title="+that.value,
					method:"GET",
					data:data,
					success:function(res){
						// console.log(res)
						if(res.result){
							// console.log(55555555555, res.data.list)
							that.member.membersInfo = res.data.list
							that.member.membersInfo.map(function(val){
								val.name = val.contacts
								val.phone = val.mobile
								val.association = val.title
							})
						}
					},
				})
			},
			getassociation(type,area){
				var that = this
				let url;
				if(type == "市") url = "/map/screen/getSportsAssociationAll?acity_str="+area;
				else url = "/map/screen/getSportsAssociationAll?area_str="+area;
				var data = {
					token: window.getToken(),
					app_id: window.appId,
					time: timestamp(),
				}
				getAjax({
					url: url,
					method:"GET",
					data:data,
					success:function(res){
						// console.log(res)
						if(res.result){
							// console.log(55555555555, res.data.list)
							that.member.membersInfo = res.data.list
							that.member.membersInfo.map(function(val){
								val.name = val.contacts
								val.phone = val.mobile
								val.association = val.title
							})
						}
					},
				})
			}
		},
		computed: {
		    classOption () {
		        return {
					step: 0.4, // 数值越大速度滚动越快
					// limitMoveNum: this.member.membersInfo.length, // 开始无缝滚动的数据量 this.dataList.length
					limitMoveNum: 18, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: true, // 是否开启鼠标悬停stop
					direction: 1, // 0向下 1向上 2向左 3向右
					openWatch: true, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
		        }
		    }
		},
		mounted() {
			let that = this;
			let screenUserInfo = JSON.parse(window.localStorage.getItem("screenUserInfo"))
			// console.log(screenUserInfo)
			if(screenUserInfo.is_city==1){
				that.getassociation("市","绍兴市");
				that.iconShow = true;
			}else if(screenUserInfo.is_city==0){
				that.pitchon=screenUserInfo.name
				that.getassociation("区",screenUserInfo.name);
				that.iconShow = false;
			}
		}
	}
</script>

<style scoped>
	.font17{font-size: 0.17rem}
	.container{
		width: 5.96rem;
		height: 7.66rem;
		padding: 0.2rem;
		position: relative;
	}
	.title{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 0.1rem;
	}
	.title > input{
		background-color: transparent;
		border: 0.01rem solid #5D5D5D;
		border-radius: 0.2rem;
		outline:none;
		color: #646363;
		background: url(../../../assets/images/search.png) no-repeat;
		background-size: 10% 90%;
		background-position: 3% 50%;
		padding-left: 0.3rem;
		box-sizing: border-box;
		width: 2.05rem;
		height: 0.25rem;
	}
	.search{
		width: 0.57rem;
		height: 0.25rem;
		text-align: center;
		line-height: 0.25rem;
		color: #000000;
		background-color: #5589FF;
		border-radius: 0.2rem;
		position: absolute;
		right: 0.21rem;
		cursor: pointer;
	}
	.select{
		display: flex;
		align-items: center;
		margin-top: 0.3rem;
	}
	.triangle{
		width: 0px;
		height: 0px;
		border-top: 0.1rem solid #20D7FE;
		border-left: 0.1rem solid transparent;
		border-right: 0.1rem solid transparent;
		margin: 0.05rem 0rem 0rem 0.1rem;
	}
	.seamlessWarp{
		width: 5.5rem;
		height: 5.46rem;
		overflow: hidden;
	}
	#table{
		color: #7EA7E2;
		font-size: 0.14rem;
		text-align: left;
		width: 5.5rem;
		/*height: 6.96rem;*/
	}
	#table td{
		text-align: center;
		line-height: 0.3rem;
	}
	
	#thead{
		margin: 0.3rem 0rem 0.1rem 0rem;
		width: 5.5rem;
		color: #2796C4;
		font-size: 0.14rem;
		text-align: center;
	}
	#thead td:last-child{
		text-align: center;
	}
	.first{
		width: 0.16rem;
		height: 0.16rem;
		line-height: 0.18rem;
		text-align: center;
		border-radius: 50%;
		color: white;
		background-image: linear-gradient(to bottom , #FB8B2E ,#FEC554);
		margin: 0 auto;
	}
	.second{
		width: 0.16rem;
		height: 0.16rem;
		line-height: 0.18rem;
		text-align: center;
		border-radius: 50%;
		color: white;
		background-image: linear-gradient(to bottom , #FD6B87 ,#FF8FA5);
		margin: 0 auto;
	}
	.third{
		width: 0.16rem;
		height: 0.16rem;
		line-height: 0.18rem;
		text-align: center;
		border-radius: 50%;
		color: white;
		background-image: linear-gradient(to bottom , #0245D5 ,#00AEEB);
		margin: 0 auto;
	}
	.counties{
		display: inline-block;
		position: absolute;
		top:0.8rem;
		left:0;
		color:#F1F1F1;
		font-size: 0.19rem;
		text-align: center;
		background:#000000CC;
		padding:0.06rem 0rem;
		box-sizing: border-box;
		max-height:4.1rem;
		overflow-x: hidden;
		overflow-y: scroll;
		z-index: 10;
	}
	.counties::-webkit-scrollbar {
			display: none;
		}
	.countiesli{
		width:1.09rem;
		line-height: 0.40rem;
	}
	.countiesli:hover{
		color:#7EA7E2;
		cursor: pointer;
	}
</style>